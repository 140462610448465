const styles = {
  container: {
    base: 'hover:opacity-40 bars-icon cursor-pointer w-6 sm:w-8 h-6 pt-2 -ml-1 relative',
    active: 'active',
  },
  bar: {
    base: 'bar absolute w-full transition-transform duration-300',
    height: { height: '1px' },
  },
};

export default styles;
