'use client';

import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { toClassName } from 'helpers';

import { TNextClientComponent } from 'types/next';

import { useScrollPosition } from 'hooks/useScrollPosition';
import { useTranslation } from 'hooks/useTranslation';

import LangPicker from 'components/LangPicker';
import SlideOverOverlay from 'components/SlideOver/SlideOverOverlay';

import { BarsButton, SlideOverContent } from './partials';

import { styles } from '.';

type TProps = {
  socials: { accessor: string; href: string }[];
  routes: { id: string; href: string; label: string }[];
};

const Navigation: TNextClientComponent<TProps> = (props) => {
  const { socials, routes } = props;

  const params = useParams();
  const pathname = usePathname();
  const scrollPosition = useScrollPosition();

  const { t } = useTranslation(params?.lang as string);

  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleGenerateStylesFromScrollPosition = () => {
    const paths = [
      '', // Root
      '/biography',
      '/projects/',
    ];

    let results = {
      root: toClassName(
        scrollPosition < 25 ? '' : 'bg-white dark:bg-black',
        'sm:!bg-transparent text-black dark:text-white',
      ),
      body: scrollPosition < 25 ? 'sm:opacity-100' : 'sm:opacity-0',
      button: 'bg-black dark:bg-white',
      text: 'text-black dark:text-white',
    };

    const handleCheckHasHeader = () => {
      let result = false;

      paths.forEach((path) => {
        if (
          pathname.split(params?.lang as string)[1].includes(path) &&
          path !== ''
        ) {
          result = true;
        }
      });

      if (pathname === `/${params?.lang}`) {
        result = true;
      }

      return result;
    };

    // Check if current path matches one of the provided paths.
    if (handleCheckHasHeader()) {
      const $header = (document ?? undefined)?.getElementById('header');
      const headerClientHeight = $header?.clientHeight ?? 0;
      const navigationSectionMarginOnDesktop = 58.5;

      const hasUserScrolled = scrollPosition > 5;
      const hasUserScrolledPastHeader =
        scrollPosition > headerClientHeight - navigationSectionMarginOnDesktop;

      results = {
        root: hasUserScrolled
          ? hasUserScrolledPastHeader
            ? 'bg-white !text-black dark:bg-black dark:!text-white sm:!bg-transparent'
            : 'bg-white !text-black dark:bg-black dark:!text-white sm:!bg-transparent sm:!text-white'
          : 'text-white',
        body: hasUserScrolled ? 'sm:opacity-0' : 'sm:opacity-100',
        button: hasUserScrolled
          ? hasUserScrolledPastHeader
            ? 'bg-black dark:bg-white'
            : 'bg-black dark:bg-white sm:bg-white'
          : 'bg-white',
        text: hasUserScrolled
          ? hasUserScrolledPastHeader
            ? '!text-black dark:!text-white'
            : '!text-black dark:!text-white sm:!text-white'
          : '!text-white',
      };
    }

    return results;
  };

  return (
    <nav
      className={toClassName(
        styles.root,
        isMounted && handleGenerateStylesFromScrollPosition().root,
      )}
    >
      <div className={styles.container}>
        <div className={styles.head} style={{ zIndex: 1 }}>
          <SlideOverOverlay
            onClose={() => setIsOpen(false)}
            trigger={({ handleOpen, handleClose }) => (
              <BarsButton
                className={
                  isMounted
                    ? handleGenerateStylesFromScrollPosition().button
                    : ''
                }
                isOpen={isOpen}
                setIsOpen={() => {
                  if (isOpen) {
                    handleClose();
                    setIsOpen(false);
                    return;
                  }

                  handleOpen();
                  setIsOpen(true);
                }}
              />
            )}
          >
            {({ handleClose }) => (
              <SlideOverContent
                lang={params?.lang as string}
                routes={routes}
                socials={socials}
                handleClose={handleClose}
                setIsOpen={setIsOpen}
              />
            )}
          </SlideOverOverlay>
        </div>
        <div
          className={toClassName(
            styles.body,
            isMounted && handleGenerateStylesFromScrollPosition().body,
          )}
        >
          <p className="flex flex-col sm:flex-row">
            {pathname?.split('/').length === 3
              ? pathname?.split('/')[2] &&
                t(`component.navigation.label.${pathname?.split('/')[2]}`)
              : pathname?.split('/').length === 4
                ? pathname?.split('/')[3] && (
                    <>
                      <span className="opacity-50 hover:opacity-100">
                        <Link
                          href={`/${params?.lang}/${pathname?.split('/')[2]}`}
                        >{`${t(`component.navigation.label.${pathname?.split('/')[2]}`)}/`}</Link>
                      </span>
                      <span className="">
                        {pathname
                          ?.split('/')[3]
                          .replaceAll('-', ' ')
                          .replace(' and ', ' & ')
                          .replace(' en ', ' & ')}
                      </span>
                    </>
                  )
                : ''}
          </p>
          <div
            className={toClassName(
              styles.center,
              pathname !== `/${params?.lang}` && 'hidden',
            )}
          >
            <Link href="/">
              <p className="hover:opacity-40">Jerom Verschoote</p>
            </Link>
          </div>
          <div></div>
        </div>
        <div className={styles.foot}>
          <LangPicker
            className={
              isMounted ? handleGenerateStylesFromScrollPosition().text : ''
            }
          />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
