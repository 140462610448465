const styles = {
  container: 'bg-black font-nbi-pro',

  head: 'py-24 flex justify-center items-center border-t border-gray-900',
  body: {
    container: 'border-t border-gray-900 border-t-0.5',
    content:
      'py-8 flex flex-col-reverse gap-y-4 sm:flex-row justify-between items-center text-white text-xs',
  },
  title:
    'text-4xl text-white font-bold tracking-display hover:underline underline-offset-4 pb-2',

  list: 'flex flex-row items-center gap-x-5',
  icon: 'text-white hover:opacity-40 text-base',
};

export default styles;
