export const toClassName = (...values: (boolean | string | undefined)[]) => {
  return values.filter(Boolean).join(' ');
};

export const handleWait = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

// export const handleGetData = async <T>(path: string) => {
//   const endpoint = `http://localhost:3000/api${path}`;
//   const options = {
//     method: 'GET',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//   };

//   const response = await fetch(endpoint, options);
//   const result = await response.json();

//   if (!response.ok) {
//     throw new Error(result);
//   }

//   return result as { data: T };
// };
