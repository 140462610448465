'use client';

import { useParams, usePathname } from 'next/navigation';
import React from 'react';

import i18n from 'config/i18n';
import { toClassName } from 'helpers';

import { TNextClientComponent } from 'types/next';

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'components/Dropdown';
import Icon from 'components/Icon';

import { styles } from '.';

type TProps = {
  className?: string;
};

const LangPicker: TNextClientComponent<TProps> = (props) => {
  const { className } = props;

  const params = useParams();
  const pathname = usePathname();

  const languages = i18n.locales.map((location) => ({
    id: location,
    label: location,
    href: pathname?.replace(params?.lang as string, location),
  }));

  return (
    <Dropdown>
      <DropdownButton outline className={toClassName(styles.button, className)}>
        {params?.lang}
        <Icon accessor="chevron-down" className={styles.icon} />
      </DropdownButton>
      <DropdownMenu>
        {languages.map((item) => (
          <DropdownItem key={item.id} href={item.href} className={styles.item}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LangPicker;
