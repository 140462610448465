const styles = {
  container:
    'bg-white dark:bg-black text-black dark:text-white flex flex-col sm:gap-y-1 font-nbi-pro font-bold items-start h-full border-r dark:border-gray-900',
  head: 'hover:opacity-40 cursor-pointer py-6 sm:py-12 px-6 sm:px-10 flex flex-row gap-x-4 sm:gap-x-8 items-center',
  body: 'flex flex-col -gap-y-1 px-6 sm:px-10 grow h-full',
  foot: 'text-black dark:text-white flex items-center gap-x-4 py-6 sm:py-12 px-6 sm:px-10 !text-base',

  closeIcon: {
    container: 'flex relative',
    leg: 'absolute w-4 sm:w-6 bg-black dark:bg-white',
    label: 'uppercase font-thin text-xs sm:text-sm ml-2 mt-0.5',
  },

  route: {
    base: 'text-5xl sm:text-6xl font-bold tracking-display',
    inactive: 'cursor-pointer hover:opacity-40',
    active: 'opacity-40 line-through cursor-default',
  },

  image: 'hover:opacity-40 cursor-pointer',
};

export default styles;
