import React, { FC } from 'react';

import { toClassName } from 'helpers';

import { styles } from '.';

type TProps = {
  isOpen: boolean;
  setIsOpen: (isOpen?: boolean) => void;
  onClick?: () => void;
  className: string;
};

const BarButton: FC<TProps> = (props) => {
  const { isOpen, setIsOpen, onClick, className } = props;

  return (
    <div
      className={toClassName(
        styles.container.base,
        isOpen && styles.container.active,
      )}
      onClick={() => {
        setIsOpen(!isOpen);
        onClick && onClick();
      }}
    >
      <div
        className={toClassName(styles.bar.base, className)}
        style={styles.bar.height}
      />
      <div
        className={toClassName(styles.bar.base, 'mt-2', className)}
        style={styles.bar.height}
      />
    </div>
  );
};

export default BarButton;
