const base = {
  container: 'relative z-20',

  background: {
    container: 'fixed inset-0 bg-black bg-opacity-75 transition-opacity',
    transition: {
      enter: 'ease-in-out duration-500',
      enterFrom: 'opacity-0',
      enterTo: 'opacity-100',
      leave: 'ease-in-out duration-500',
      leaveFrom: 'opacity-100',
      leaveTo: 'opacity-0',
    },
  },

  layout: {
    container: 'fixed inset-0 overflow-hidden',
    content: 'absolute inset-0 overflow-hidden',
    inner: 'pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10',
  },

  panel: {
    container: 'pointer-events-auto w-screen bg-white sm:max-w-sm',
    transition: {
      enter: 'transform transition ease-in-out duration-500 sm:duration-700',
      enterFrom: '-translate-x-full',
      enterTo: 'translate-x-0',
      leave: 'transform transition ease-in-out duration-500 sm:duration-700',
      leaveFrom: 'translate-x-0',
      leaveTo: '-translate-x-full',
    },
  },
};

// const xs = {
//   panel: {
//     container: 'sm:max-w-xs',
//   },
// };

// const sm = {
//   panel: {
//     container: 'sm:max-w-sm',
//   },
// };

// const md = {
//   panel: {
//     container: 'sm:max-w-md',
//   },
// };

// const lg = {
//   panel: {
//     container: 'sm:max-w-2xl',
//   },
// };

// const xl = {
//   panel: {
//     container: 'sm:max-w-4xl',
//   },
// };

const styles = {
  ...base,

  // xs,
  // sm,
  // md,
  // lg,
  // xl,
};

export default styles;
