const styles = {
  root: 'fixed top-0 left-0 right-0 z-10 duration-500 z-20',
  container:
    'font-nbi-pro py-6 sm:py-12 px-6 sm:px-10 flex flex-row items-center justify-between',

  head: 'flex flex-row items-center gap-x-4 sm:gap-x-8 uppercase text-xs sm:text-sm tracking-wider',
  body: 'uppercase text-xs sm:text-sm tracking-wider flex justify-between w-full px-6 sm:px-[2.1rem] duration-500 items-start',
  foot: 'flex flex-row items-center justify-center',

  center:
    'sm:w-screen sm:left-0 sm:right-0 sm:fixed sm:flex sm:justify-center pointer-events-none',

  mobile: 'bg-white dark:bg-black sm:bg-transparent',
};

export default styles;
