const styles = {
  container: '',
  button:
    '!text-xs !font-light font-display !uppercase !p-0 border-none ring-none -mr-4',
  icon: '-ml-4 w-2',

  item: '!text-xs',
};

export default styles;
